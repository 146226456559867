
import InstagramLoginCard from '@/components/instagram/InstagramLoginCard.vue';
import { InstagramPlatformData, PublicBot, SupportedPlatform } from 'ignite360-core';
import _merge from 'lodash.merge';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { InstagramApi } from '@/api/InstagramApi';

@Component({
  name: 'public-social-login-instagram',
  components: { InstagramLoginCard },
})
export default class PublicSocialLoginInstagram extends Vue {
  @Prop({ required: true, type: Object })
  readonly bot!: PublicBot;

  isPublishingTestPost = false;

  onConnect(data: Partial<InstagramPlatformData>) {
    const newBot: PublicBot = _merge({}, this.bot, {
      platformData: {
        [SupportedPlatform.Instagram]: data,
      },
    });
    this.$emit('update:bot', newBot);
  }

  onDisconnect() {
    const newBot: PublicBot = _merge({}, this.bot, {
      platformData: {
        [SupportedPlatform.Instagram]: {
          pageId: '',
          pageName: '',
          pageAccessToken: '',
          businessAccountId: '',
        },
      },
    });
    this.$emit('update:bot', newBot);
  }

  get canPublishTestPost(): boolean {
    return !!(
      this.bot.platformData[SupportedPlatform.Instagram].pageAccessToken &&
      this.bot.platformData[SupportedPlatform.Instagram].businessAccountId &&
      this.bot.platformData[SupportedPlatform.Instagram].pageId
    );
  }

  async publishTestPost() {
    const instagramData = this.bot.platformData[SupportedPlatform.Instagram];
    if (
      !instagramData?.pageAccessToken ||
      !instagramData?.pageId ||
      !instagramData?.businessAccountId
    ) {
      return;
    }

    this.isPublishingTestPost = true;
    try {
      await InstagramApi.publishTestPost(this.bot.id, {
        pageAccessToken: instagramData.pageAccessToken,
        pageId: instagramData.pageId,
        businessAccountId: instagramData.businessAccountId,
      });
      this.$notify.success('Successfully published test post');
    } catch (e) {
      this.$notify.error({
        title: 'Publishing a test post failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isPublishingTestPost = false;
    }
  }
}
